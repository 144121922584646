
const validateStringForNull = (value) => (
  !(value === null || value === undefined || value === '')
);

const validateNumber = (value) => (
  !(value === null || value === undefined || value === '' || isNaN(value))
);

const validateEmailId = (value) => {
  const regexp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  return regexp.test(value);
};

export { validateStringForNull, validateEmailId, validateNumber };
