class Utils {
  static getInitialStatusBarState() {
    return {
      open: false,
      severity: 'info',
      message: ''
    };
  }
}

export { Utils };
