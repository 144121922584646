class APIData {
  constructor(apiPath, method, isAuthRequired) {
    this.apiPath = apiPath;
    this.method = method;
    this.isAuthRequired = isAuthRequired ?? true;
  }

  getAPIPath() {
    return this.apiPath;
  }

  getMethod() {
    return this.method;
  }

  getIsAuthRequired() {
    return this.isAuthRequired;
  }

  static sendUserDetails = new APIData('contact', 'POST', false);
}

export default APIData;

