
// eslint-disable-next-line no-extend-native
String.prototype.format = function(...args) {
  let formatted = this;
  for (let i = 0; i < args[0].length; i++) {
    const regexp = new RegExp('\\{'+i+'\\}', 'gi');
    formatted = formatted.replace(regexp, args[0][i]);
  }
  return formatted.toString();
};

class APIError extends Error {
  constructor(status, message, data, success) {
    super(message);
    this.status = status;
    this.data = data;
    this.success = success;
  }
}

class Services {
  static baseURI = 'https://dockit.teqshack.com/api/v1/';

  static getAuthHeaders(isHeadersNeeded) {
    if (isHeadersNeeded) {
      const loginhash = CookieUtils.getLoginhash();
      return { login_hash: loginhash };
    }
    return {};
  };

  static sendRequestInternal(uri, requestOptions, apiData, apiToken, callbackValues, processSuccess, processError) {
    const fullURI = `${Services.baseURI}${uri}`;
    fetch(fullURI, requestOptions)
      .then(async (res) => {
        const data = await res.json();
        if (res.ok && data.success === 1) {
          processSuccess(apiData, apiToken, callbackValues, data);
        } else {
          throw new APIError(res.status, data.message, data.data, data.success);
        }
      })
      .catch((err) => {
        let errObj = err;
        if (err instanceof TypeError) {
          errObj = new Error('Unexpected failure! Check your internet connection');
        } else if (err instanceof DOMException) {
          if (callbackValues == undefined) {
            callbackValues = {};
          }
          callbackValues.suppressSnackBar = true;
          console.log(errObj);
        } else {
          console.log(errObj);
        }
        processError(apiData, apiToken, callbackValues, errObj);
      });
  };

  static sendBackendRequest(requestObject, apiToken, processSuccess, processError, callbackValues) {
    const requestOptions = {};
    requestOptions.method = requestObject.apiData.getMethod();
    let finalURI = requestObject.apiData.getAPIPath();
    if (requestObject.uriValues !== undefined) {
      finalURI = finalURI.format(requestObject.uriValues);
    }
    requestOptions.headers = Services.getAuthHeaders(requestObject.apiData.getIsAuthRequired());
    if (requestObject.params != undefined) {
      if (requestObject.apiData.getMethod() == 'GET') {
        finalURI+='?'+requestObject.params;
      } else {
        requestOptions.body = JSON.stringify(requestObject.params);
        requestOptions.headers['Content-Type'] = 'application/json';
      }
    }
    Services.sendRequestInternal(finalURI, requestOptions, requestObject.apiData, apiToken, callbackValues, processSuccess, processError);
  };
}
export default Services;
