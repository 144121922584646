import { Box, Button, Card, CardContent, Divider, Grid, IconButton,
  Link, Typography, useMediaQuery } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import React, { useEffect, useState } from 'react';
import NavBar from '../components/NavBar';
import FormDialog from '../components/FormDialog';

const Home = () => {
  const servicesCards = {
    'Waiting Room Management': '/static/images/Search.png',
    'AI Powered Suggestion': '/static/images/Aipoweredsuggestion.png',
    'Patient Followup': '/static/images/Patientfollowup.png',
    'Billing Management': '/static/images/Mobile-payment.png',
    'EMR Record': '/static/images/Health-report.png',
    'Patient Engagement': '/static/images/Patientengagement.png'
  };

  const isXsScreen = useMediaQuery('(max-width:600px)');
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const featuresCards = {
    Apex: '/static/images/Admin.png',
    Pulse: '/static/images/Doctor.png',
    Catena: '/static/images/Nurse.png',
    Wave: '/static/images/Receptionist.png'
  };

  useEffect(() => {
    const smoothScroll = () => {
      document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
        anchor.addEventListener('click', function(e) {
          e.preventDefault();
          const targetId = e.target.getAttribute('href').substring(1);
          const targetSection = document.getElementById(targetId);
          if (targetSection == null) {
            window.scrollTo({ behavior: 'smooth', top: 0 });
          } else {
            targetSection.scrollIntoView({ behavior: 'smooth' });
          }
        });
      });
    };
    smoothScroll();
    return () => {
      document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
        anchor.removeEventListener('click', smoothScroll);
      });
    };
  }, []);

  const useWindowSize = () => {
    const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerWidth, window.innerHeight]);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    return size;
  };

  const [screenWidth] = useWindowSize();

  const getFeaturesCards = () => {
    const arr = [];
    Object.keys(featuresCards).map((key) => {
      const card = getFeatureCard(key, featuresCards[key]);
      arr.push(card);
    });
    return arr;
  };

  const getFeaturesContentText = () => {
    if (isXsScreen) {
      return <Typography variant='content' sx={{ fontSize: { xs: '13pt', sm: '14pt' } }}>
        With DocKit, one click empowers you to deliver exceptional care, improve patient satisfaction,
        and thrive in today&apos;s dynamic healthcare landscape. Endless possibilities await.
      </Typography>;
    } else {
      return <Typography variant='content' sx={{ fontSize: { xs: '13pt', sm: '14pt' } }}>
        Empower Your Clinic in Today&apos;s Digital Age: DocKit offers advanced features designed to streamline workflows,
        boost efficiency, and unlock growth potential for your practice. With DocKit,
        one click empowers you to deliver exceptional care, improve patient satisfaction,
        and thrive in today&apos;s dynamic healthcare landscape. Endless possibilities await.
      </Typography>;
    }
  };

  const getFeatureCardText = (userType) => {
    if (userType == 'Apex') {
      return (
        <Typography sx={{ mt: 1 }} variant='subContentText'>
          Manage your multiple clinic&apos;s operations seamlessly from a single application. Track doctor performance, patient growth,
          and staff management to boost your clinic&apos;s efficiency and revenue.
        </Typography>
      );
    } else if (userType == 'Pulse') {
      return (
        <Typography sx={{ mt: 1 }} variant='subContentText'>
          Effortlessly manage patient records, appointments, and medical histories with an intuitive interface.
          Simplify daily operations, allowing more focus on patient care and treatment.
        </Typography>
      );
    } else if (userType == 'Catena') {
      return (
        <Typography sx={{ mt: 1 }} variant='subContentText'>
          Handle patient appointments, medical records, and treatment plans with ease. Support doctors by managing their OPs,
          ensuring smooth and efficient patient care.
        </Typography>
      );
    } else {
      return (
        <Typography sx={{ mt: 1 }} variant='subContentText'>
          Welcome and guide with ease. Equip receptionists with the tools to efficiently manage appointments, registrations,
          and patient flow, ensuring a smooth and welcoming clinic environment.
        </Typography>
      );
    }
  };

  const getFeatureCard = (userType, imageUrl) => {
    return (
      <Grid item xs={12} sm={6} md={3} key={imageUrl}>
        <Card
          sx={{
            border: '2px solid #E0E0E0',
            borderRadius: '10px',
            transition: 'border-color 0.3s',
            height: '100%',
            '&:hover': {
              borderColor: 'main.primary',
              boxShadow: '0 4px 10px #000'
            }
          }}
        >
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Grid container>
              <Grid item xs={12} sx={{ display: 'flex', textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                <Typography variant='cardText'
                  sx={{
                    width: {
                      md: '10rem',
                      lg: '10rem'
                    },
                    mb: { xs: 1, sm: 2, md: 1 }
                  }}
                >{userType}</Typography>
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }} >
                <img
                  src={imageUrl}
                  alt=""
                  style={{
                    height: '12rem'
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={{ pl: 1, pt: 2 }}>
                {getFeatureCardText(userType)}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  const getServicesCard = ( text, imageUrl, isLastCard ) => {
    return (
      <Grid item xs={12} sm={6} md={4} key={imageUrl}>
        <Card
          sx={{
            border: '2px solid #E0E0E0',
            borderRadius: '10px',
            transition: 'border-color 0.3s',
            '&:hover': {
              borderColor: 'main.primary',
              boxShadow: '0 4px 10px #000'
            }
          }}
        >
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <img
              src={imageUrl}
              alt=""
              style={{
                height: '5rem'
              }}
            />
            <Typography id={isLastCard ? 'features' : ''} sx={{ mt: 1, fontSize: { md: '14pt', lg: '16pt' } }} variant='cardText'>{text}</Typography>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  const getServicesCards = () => {
    const arr = [];
    Object.keys(servicesCards).map((key, i) => {
      const isLastCard = i == (Object.keys(servicesCards).length - 1);
      const card = getServicesCard(key, servicesCards[key], isLastCard);
      arr.push(card);
    });
    return arr;
  };

  const getEmpoweringDoctorContainer = () => {
    return (
      <Grid item container xs={12} sx={{ mt: { xs: 6, sm: 0, lg: 2 } }}
      >
        <Grid item container sx={{ px: { xs: 2, sm: 4, md: 6, lg: 15.5 } }}>
          <Grid item xs={12}>
            <Card
              elevation={6}
              sx={{ backgroundColor: 'main.lightBlue', overflow: 'visible', borderRadius: '10px' }} >
              <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Grid item xs={12} sm={5} md={6} lg={5} sx={{ ml: { sm: -4, md: 0 } }}>
                  <Box
                    component='img'
                    sx={{ mt: { xs: -5, sm: -5, lg: -7 }, position: 'relative', zIndex: 1,
                      ml: { xs: screenWidth < 380 ? -6.5 : 0 },
                      height: {
                        xs: '14rem',
                        sm: '16rem',
                        md: '20rem',
                        lg: '20rem',
                        xl: '24rem'
                      }
                    }}
                    src='/static/images/Doctor-illustration.png'
                  />
                </Grid>
                <Grid item container direction='row'
                  sx={{ mt: { xs: 0, sm: 3 }, px: { xs: 2, md: 4, lg: 2 } }}
                  xs={12} sm={7} md={6} lg={5.5}>
                  <Grid item xs={12} sx={{ px: { xs: 1, sm: 0 } }}>
                    <Typography variant='contentHeading' sx={{ color: 'main.primary', display: 'flex',
                      fontSize: { xs: screenWidth < 380 ? '20pt' : '22pt', sm: '21pt', md: '22pt' },
                      textAlign: { xs: 'center', sm: 'flex-start' } }}>Empowering Doctors Everyday</Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ pt: { xs: 2, sm: 2, md: 0 },
                    ml: { xs: screenWidth < 380 ? 1 : 3, sm: '3px', md: 0 } }} >
                    <Typography variant="content" >We&apos;re so confident you&apos;ll fall in love with
                      DocKit, we&apos;ll let you try our entire suite of EHR
                      practice tools for free.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      height: 'fit-content',
                      pt: { xs: 4, sm: 2, md: 0 },
                      pb: { xs: 2, sm: 2, md: 0 }
                    }}
                  >
                    <Button variant="outlined"
                      endIcon={<ArrowForwardIcon sx={{ fontSize: '2rem !important', mt: 0.5, ml: -1 }} />}
                      sx={{
                        backgroundColor: 'main.white',
                        color: 'main.primary',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 1)',
                        '&:hover': {
                          borderColor: 'main.primary',
                          backgroundColor: 'main.white',
                          boxShadow: '0 4px 10px #000'
                        },
                        mb: 1,
                        whiteSpace: 'nowrap',
                        fontSize: { xs: screenWidth < 380 ? '13pt' : '1.3rem' }
                      }}
                      onClick={() => setIsDialogOpen(true)}
                    >
                      Schedule a free demo
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          {isXsScreen ? '' : getUnlockThePowerText()}
        </Grid>
      </Grid>
    );
  };

  const getUnlockThePowerText = () => {
    return (
      <Grid item xs={12} display='flex' justifyContent='center'
        sx={{ mt: { xs: 0, sm: 4 }, mb: { xs: 4, sm: 4 },
          pl: { xs: 9, sm: 0 }, pr: { xs: 2, sm: 0 }
        }}>
        <Typography variant='content' textAlign='flex-start'
          sx={{
            display: 'flex',
            width:
              { xs: '22rem',
                sm: '43rem',
                md: '38rem',
                lg: '45rem'
              }
          }}
        >
          Unlock the power of our comprehensive system tailored for seamless practice
          management in the digital age.
        </Typography>
      </Grid>
    );
  };

  return (
    <React.Fragment>
      <Grid container>
        <NavBar />
        <Grid item container
          xs={12}>
          {/* 2nd container */}
          <Grid item container xs={12}>
            <Grid item container xs={12}
              sx={{
                backgroundImage: { xs: 'url(/static/images/Subtract1Mobile.png)',
                  sm: 'url(/static/images/Subtract1Tablet.png)', md: 'url(/static/images/Subtract.png)',
                  lg: 'url(/static/images/Subtract.png)' },
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                zIndex: 1,
                minHeight: { lg: 'calc(100vh - 276px)' },
                mt: 4,
                backgroundPosition: { sm: 'top right', md: 'top' }
              }}
            >
              <Grid item container xs={12}
                sx={{
                  px: {
                    xs: 2,
                    sm: 2,
                    lg: 12
                  }
                }}
              >
                <Grid item container xs={12} sm={6} md={7}
                  sx={{
                    alignContent: 'center',
                    display: 'flex',
                    pl: {
                      xs: 0,
                      sm: 6,
                      md: 8,
                      lg: 6.75
                    },
                    pt: { xs: 5, md: 5, lg: 1, xl: 9 }, mt: { sm: 6, md: 0 } }}
                >
                  <Grid item xs={12} sx={{ px: { xs: 2, sm: 0 }, width: { xs: '1rem' } }} >
                    <Typography variant="contentHeading"
                      sx={{
                        color: 'main.primary',
                        display: 'flex',
                        textAlign: { xs: 'center', sm: 'start' },
                        fontSize: { xl: '30pt' }
                      }}>Healthier Practices, Happier Patients</Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ mt: { xs: 2, sm: 2, lg: 1 }, px: { xs: 5, sm: 0 } }}>
                    <Typography variant="contentSubHeading"
                      sx={{ display: 'flex', textAlign: { xs: 'center', sm: 'start' },
                        fontSize: { xs: screenWidth < 380 ? '21pt': '22pt', xl: '30pt' } }}
                    >Empowering Clinics, One click at a time</Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ mt: { xs: 5, sm: 3, md: 5, lg: 4.5 }, pl: { xs: 4, sm: 0 } }}>
                    <Typography variant="content"
                      sx={{
                        display: 'flex',
                        width:
                          { xs: screenWidth < 380 ? '18rem' : '21rem',
                            sm: '26rem',
                            md: '26rem',
                            xl: '35rem'
                          }
                      }}
                    >Elevate the Art of Primary Care with DocKit, a sophisticated yet user-friendly platform
                      to digitize clinics and to optimize patient interactions.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ mt: { xs: 5, sm: 3, md: 5 }, pb: { sm: 6 },
                    display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' } }}>
                    <Button variant="outlined"
                      endIcon={<ArrowForwardIcon sx={{ fontSize: '2rem !important', mt: 0.5, ml: -0.5 }} />}
                      sx={{
                        backgroundColor: 'main.white',
                        color: 'main.primary',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 1)',
                        '&:hover': {
                          borderColor: 'blue',
                          backgroundColor: 'main.white',
                          boxShadow: '0 4px 10px #000'
                        }
                      }}
                      onClick={() => setIsDialogOpen(true)}
                    >
                      Schedule a free demo
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={5}
                  sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <Box
                    component="img"
                    sx={{
                      height: {
                        xs: screenWidth < 380 ? '19rem' : '21rem',
                        md: '25rem',
                        lg: '26rem'
                      },
                      zIndex: -1,
                      ml: { xs: 0, sm: 1, md: -5, lg: 0 }
                    }}
                    alt='DocKit'
                    src='/static/images/Doctors.png'
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xs={12} justifyContent="center"
              alignItems="stretch" sx={{ mx: { xs: 4, sm: 4, md: 6, lg: 16 }, borderRadius: '10px', my: 3,
                backgroundColor: { xs: 'white', sm: 'main.lightBlue' } }} >
              <Grid item container xs={12} sm={4} sx={{ backgroundColor: 'main.lightBlue',
                borderRight: { xs: 0, sm: 1 }, borderRadius: { xs: '10px', sm: '0px' },
                borderColor: '#000000', my: { xs: 0, sm: 3 }, px: 2, py: { xs: 2, sm: 0 }
              }}>
                <Grid item xs={12} sm={4} display='flex' justifyContent='center' alignItems='center'>
                  <Box component="img" src='/static/images/Convenience.png' sx={{ height: '3.8rem', mr: 1 }} />
                </Grid>
                <Grid item container xs={12} sm={8} sx={{ px: 2 }}>
                  <Grid item xs={12} sm={8} display='flex' justifyContent={{ xs: 'center', sm: 'flex-start' }}>
                    <Typography variant="subContent"
                      sx={{
                        color: 'main.primary'
                      }}>
                      Convenience
                    </Typography>
                  </Grid>
                  <Grid item xs={12} display='flex' justifyContent={{ xs: 'center', sm: 'flex-start' }}
                    sx={{ maxWidth: { sm: '8rem', lg: '10rem' } }}
                  >
                    <Typography variant="subContentText"
                      sx={{
                        color: 'black',
                        display: 'flex',
                        fontSize: screenWidth < 380 ? '11pt' : '12pt'
                      }}>
                      Running your clinic is made easier
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container xs={12} sm={4} display='flex' justifyContent='center'
                sx={{ borderRight: { xs: 0, sm: 1 }, borderColor: '#000000', my: { xs: 3, sm: 3 },
                  borderRadius: { xs: '10px', sm: '0px' },
                  backgroundColor: 'main.lightBlue', px: 2, py: { xs: 2, sm: 0 } }}>
                <Grid item xs={4} display='flex' justifyContent='center' alignItems='center'>
                  <Box component="img" src='/static/images/7Support.png' sx={{ height: '2.8rem', mb: 1 }} />
                </Grid>
                <Grid item container xs={12} sm={8}>
                  <Grid item xs={12} sm={8} display='flex' justifyContent={{ xs: 'center', sm: 'flex-start' }}>
                    <Typography variant="subContent" sx={{ color: 'main.primary', textWrap: 'nowrap' }}>
                      Support
                    </Typography>
                  </Grid>
                  <Grid item xs={12} display='flex' justifyContent={{ xs: 'center', sm: 'flex-start' }}
                    sx={{ maxWidth: { sm: '8rem', lg: '10rem' } }}
                  >
                    <Typography variant="subContentText"
                      id='services'
                      sx={{
                        color: 'black',
                        display: 'flex',
                        fontSize: screenWidth < 380 ? '11pt' : '12pt'
                      }}>
                      Fast and reliable customer support
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container xs={12} sm={4} display='flex' justifyContent='center'
                sx={{ my: { xs: 0, sm: 3 }, py: { xs: 2, sm: 0 },
                  backgroundColor: 'main.lightBlue', px: 2, borderRadius: { xs: '10px', sm: '0px' } }}>
                <Grid item xs={4} display='flex' justifyContent='center' alignItems='center'>
                  <Box component="img" src='/static/images/Affordable.png' sx={{ height: '3.5rem' }} />
                </Grid>
                <Grid item container xs={12} sm={8}>
                  <Grid item xs={12} sm={8} display='flex' justifyContent={{ xs: 'center', sm: 'flex-start' }}>
                    <Typography variant="subContent" sx={{ color: 'main.primary' }} id='our_services'>
                      Affordable
                    </Typography>
                  </Grid>
                  <Grid item xs={12} display='flex' justifyContent={{ xs: 'center', sm: 'flex-start' }}
                    sx={{ maxWidth: { sm: '7.9rem', lg: '8rem' } }}
                  >
                    <Typography variant="subContentText"
                      sx={{
                        color: 'black',
                        display: 'flex',
                        fontSize: screenWidth < 380 ? '11pt' : '12pt'
                      }}>
                      Avail the product at reasonable cost
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={12}
            sx={{
              pt: { xs: 0, sm: 1, md: 1, lg: 2 },
              px: { xs: 4, sm: 4, md: 6, lg: 15.5 } }}
          >
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mb: { xs: 4, sm: 4 } }}>
              <Typography variant="contentHeading" sx={{ color: 'main.primary' }}>
                Our Services
              </Typography>
            </Grid>
            <Grid item container xs={12} rowSpacing={4} columnSpacing={4}>
              {getServicesCards()}
            </Grid>
          </Grid>
          <Grid item xs={12} >
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', my: 4, px: 2 }}>
              <Typography variant="contentHeading" sx={{ color: 'main.primary', textAlign: 'center' }}>
                All-in-One Clinic Management Suite
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ px: { xs: 4, sm: 4, md: 6, lg: 15 } }}>
              {getFeaturesContentText()}
            </Grid>
            <Grid item container
              sx={{
                backgroundImage: 'url(/static/images/Productbackground.png)',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                mt: 4
              }}
              rowSpacing={2}
            >
              <Grid item container sx={{ px: { xs: 4, sm: 4, md: 6, lg: 15 } }} rowSpacing={2}>
                <Grid item container xs={12} rowSpacing={4} columnSpacing={4}>
                  {getFeaturesCards()}
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-end' } }}>
                  <Button variant="outlined"
                    endIcon={<ArrowForwardIcon sx={{ fontSize: '2rem !important', mt: 0.5, ml: -1 }} />}
                    sx={{
                      backgroundColor: 'main.white',
                      color: 'main.primary',
                      boxShadow: '0px 2px 4px rgba(0, 0, 0, 1)',
                      '&:hover': {
                        borderColor: 'main.primary',
                        backgroundColor: 'main.white',
                        boxShadow: '0 4px 10px #000'
                      },
                      mb: { xs: 4, md: 1, lg: 2.4 },
                      whiteSpace: 'nowrap',
                      fontSize: '1.3rem',
                      height: 'fit-content'
                    }}
                    onClick={() => setIsDialogOpen(true)}
                  >
                    Schedule a free demo
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={12}
          sx={{
            justifyContent: 'space-between',
            mx: {
              xs: 4,
              sm: 4,
              md: 6,
              lg: 15.5
            },
            minHeight: { xs: '60vh', sm: '60vh', md: '80vh', lg: '100vh' },
            alignItems: 'stretch',
            pt: 4 }}>
          <Grid item xs={12} sm={5.8} md={5.8} lg={5.8}
            sx={{ borderRadius: '0px', minHeight: { xs: '60vh', sm: '54vh', md: '60vh' }, mb: 4 }}
          >
            <Card
              elevation={6}
              sx={{
                borderRadius: '10px',
                backgroundImage: 'url(/static/images/Group1.png)',
                height: '100%',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundPositionX: { xs: screenWidth < 380 ? '-43px' : '-56px', sm: '-72px',
                  md: screenWidth < 950 ? '-74px' : '0px', xl: '0px' },
                backgroundPositionY: { lg: '-50px', xl: '0px' }
              }}
            >
            </Card>
          </Grid>
          <Grid item xs={12} sm={5.8} md={5.8} lg={5.8} sx={{ mb: 4 }}>
            <Card
              elevation={6}
              sx={{
                borderRadius: '10px',
                height: '100%'
              }}
            >
              <Grid container sx={{ px: { xs: 3, sm: 3 } }}>
                <Grid item xs={12} sx={{ py: { xs: screenWidth < 380 ? 3: 6, sm: 3, md: 7, xl: 12 },
                  px: { xs: 2, sm: 0, lg: 4 } }} display='flex' justifyContent='center'>
                  <Typography textAlign='center'variant="contentHeading" sx={{ color: 'main.primary',
                    fontSize: { xs: screenWidth < 380 ? '19pt' : '22pt', xl: '30pt' } }}>
                  Redefining the patient experience
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ pl: { xs: 3, sm: 4, xl: 10 }, pr: { xs: 0, sm: 4 } }}>
                  <img
                    src='/static/images/Quotation-mark.png'
                    alt=''
                    style={{
                      height: isXsScreen ? '32px' : '56px'
                    }}
                  />
                </Grid>
                <Grid item xs={12} sx={{ pl: { xs: 3, sm: 4, xl: 10 }, pr: { xs: 0, sm: 4 }, mt: { xs: -3.5, sm: -3 } }}
                  display='flex' justifyContent='flex-start'>
                  <Typography variant="content" sx={{ fontSize: { xl: '19pt' } }} >
                    Enhance every patient touchpoint with
                    your practice by applying technology and
                    convenience to the patient journey.
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ pl: { xs: 3, sm: 4, xl: 10 }, pr: { xs: 0, sm: 4 }, mt: { xs: 5, md: 6, xl: 10 } }}
                  display='flex' justifyContent='flex-start'>
                  <Typography variant="content" sx={{ fontSize: { xl: '19pt' } }} >
                  Get more done with less work. Free up
                  staff to take on higher-value practice
                  work and better focus on patients
                  walking in the door.
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ mt: { xs: 5, sm: 6, xl: 10 }, mb: 5 }} display='flex' justifyContent='center'>
                  <Button variant="outlined"
                    endIcon={<ArrowForwardIcon sx={{ fontSize: '2rem !important', ml: -0.5 }}/>}
                    sx={{
                      backgroundColor: 'main.primary',
                      color: 'main.white',
                      boxShadow: '0 4px 10px #000',
                      '&:hover': {
                        borderColor: 'blue',
                        color: 'main.primary'
                      },
                      fontSize: '1.3rem',
                      height: '2.8rem', width: '16rem', borderRadius: '2rem'
                    }}
                    onClick={() => setIsDialogOpen(true)}
                  >
                    Get Started
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
        <Grid item container xs={12}
          sx={{
            backgroundImage: { xs: 'url(/static/images/Subtract2Mobile.png)',
              sm: 'url(/static/images/Subtract2Tablet.png)', md: 'url(/static/images/Subtract1.png)',
              lg: 'url(/static/images/Subtract1.png)' },
            backgroundRepeat: 'no-repeat',
            backgroundSize: { xs: 'contain', sm: 'cover' },
            zIndex: 1,
            backgroundPosition: { sm: 'bottom left', md: 'bottom' }
          }}
        >
          <Grid item container sx={{ pt: { xs: 4 }, pb: { xs: 4 },
            px: { xs: 2, sm: 2, md: 2, lg: 15 } }}>
            <Grid item container xs={12} sm={7} md={6} lg={6.25} sx={{ mt: 0 }} >
              <Grid item container sx={{ px: { xs: 0, sm: 1, md: 4 } }}>
                <Grid item xs={12} display= 'flex' justifyContent='center'>
                  <Typography variant="contentHeading" sx={{ color: 'main.primary', textAlign: 'center',
                    fontSize: { xl: '30pt' }
                  }}>
                    More medicine, less <br/> administration
                  </Typography>
                </Grid>
                { isXsScreen ? (
                  <Grid item container xs={12}>
                    <Grid item xs={12} sx={{ my: 4, pl: { xs: 6, sm: 0 },
                      pr: { xs: screenWidth < 380 ? 0 : 6, sm: 0 } }} >
                      <Typography variant="content" sx={{ fontSize: { xs: '14pt', sm: '15pt' } }}>
                      Step into the future of practice management
                      with DocKit, the all encompassing solution
                      that transforms the primary care clinics.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={5} md={6} lg={5.75}
                      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: { xs: 6 } }}>
                      <Box
                        component='img'
                        src='/static/images/Laptop-Tablet-MobileScreen.png'
                        alt=''
                        sx={
                          {
                            height: {
                              xs: '15rem',
                              sm: '16rem',
                              md: '23rem',
                              lg: '25rem'
                            }
                          }
                        }
                      />
                    </Grid>
                    <Grid item container xs={12} sx={{ pl: { xs: 6, sm: 0 } }}>
                      <Grid item display='flex' justifyContent='center'>
                        <img
                          src='/static/images/Tick.png'
                          alt=''
                          style={{
                            height: '1.8rem'
                          }}
                        />
                        <Typography variant="content" sx={{ p: 2, pt: 0 }}>Better engage with patients.</Typography>
                      </Grid>
                      <Grid item display='flex' justifyContent='center'>
                        <img
                          src='/static/images/Tick.png'
                          alt=''
                          style={{
                            height: '1.8rem',
                            backgroundColor: 'main.lightGrey'
                          }}
                        />
                        <Typography variant="content" sx={{ p: 2, pt: 0 }}>Uplift Health and Wellness Clinics</Typography>
                      </Grid>
                      <Grid item display='flex' justifyContent='center'>
                        <img
                          src='/static/images/Tick.png'
                          alt=''
                          style={{
                            height: '1.8rem'
                          }}
                        />
                        <Typography variant="content" sx={{ p: 2, pt: 0 }}>Improve clinic decision making and care</Typography>
                      </Grid>
                      <Grid item display='flex' justifyContent='center'>
                        <img
                          src='/static/images/Tick.png'
                          alt=''
                          style={{
                            height: '1.8rem'
                          }}
                        />
                        <Typography variant="content" sx={{ p: 2, pt: 0 }}>Achieve Scalable growth through data</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item container xs={12} sx={{ mt: { xs: 0, lg: -3 } }}>
                    <Grid item xs={12} sx={{ my: { xs: 5, lg: 0 }, px: { xs: 6, md: 0 } }} >
                      <Typography variant="content" sx={{ fontSize: { xs: '14pt', sm: '15pt', xl: '18pt' } }}>
                      Step into the future of practice management
                      with DocKit, the all encompassing solution
                      that transforms the primary care clinics.
                      </Typography>
                    </Grid>
                    <Grid item container xs={12} sx={{ px: { xs: 6, md: 0 } }}>
                      <Grid item xl={12} display='flex' justifyContent={ { xs: 'center', xl: 'flex-start' } }>
                        <img
                          src='/static/images/Tick.png'
                          alt=''
                          style={{
                            height: '1.8rem'
                          }}
                        />
                        <Typography variant="content" sx={{ p: 2, pt: 0 }}>Better engage with patients.</Typography>
                      </Grid>
                      <Grid xl={12} item display='flex' justifyContent={ { xs: 'center', xl: 'flex-start' } }>
                        <img
                          src='/static/images/Tick.png'
                          alt=''
                          style={{
                            height: '1.8rem',
                            backgroundColor: 'main.lightGrey'
                          }}
                        />
                        <Typography variant="content" sx={{ p: 2, pt: 0 }}>Uplift Health and Wellness Clinics</Typography>
                      </Grid>
                      <Grid item xl={12} display='flex' justifyContent={ { xs: 'center', xl: 'flex-start' } }>
                        <img
                          src='/static/images/Tick.png'
                          alt=''
                          style={{
                            height: '1.8rem'
                          }}
                        />
                        <Typography variant="content" sx={{ p: 2, pt: 0 }}>Improve clinic decision making and care</Typography>
                      </Grid>
                      <Grid item xl={12} display='flex' justifyContent={ { xs: 'center', xl: 'flex-start' } }>
                        <img
                          src='/static/images/Tick.png'
                          alt=''
                          style={{
                            height: '1.8rem'
                          }}
                        />
                        <Typography variant="content" sx={{ p: 2, pt: 0 }}>Achieve Scalable growth through data</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
            {isXsScreen ? '' : (
              <Grid item xs={12} sm={4.5} md={6} lg={5.75} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box
                  component='img'
                  src='/static/images/Laptop-Tablet-MobileScreen.png'
                  alt=''
                  sx={
                    {
                      height: {
                        xs: '15rem',
                        sm: '16rem',
                        md: '23rem',
                        lg: '25rem'
                      }, ml: '-40px'
                    }
                  }
                />
              </Grid>
            )}
            {isXsScreen ? getEmpoweringDoctorContainer() : ''}
          </Grid>
        </Grid>
        <Grid item container xs={12}
          sx={{
            mt: 2,
            backgroundImage: isXsScreen ? '' : 'url(/static/images/Subtract2.png)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'auto',
            zIndex: 1
          }}
        >
          {isXsScreen ? '' : getEmpoweringDoctorContainer()}
          <Grid item container xs={12} sx={{
            backgroundImage: isXsScreen ? 'url(/static/images/SubtracMobileFooter.png)' : '',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            zIndex: 1,
            backgroundPosition: 'right'
          }} >
            {isXsScreen ? getUnlockThePowerText() : ''}
            <Grid item container
              sx={{
                backgroundColor: 'main.primary',
                borderTopLeftRadius: '25px',
                borderTopRightRadius: '25px',
                pt: 4
              }}
            >
              <Grid item container xs={12}>
                <Grid item container xs={5} sm={3} md={3} lg={3} justifyContent='center' alignItems='center'>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <img
                      alt='DocKit'
                      src='/static/images/Logo_Desktop.png'
                      style={{
                        height: '5rem'
                      }}
                    />
                    <Button
                      type="submit"
                      size="small"
                      variant="outlined"
                      href='https://dockit.teqshack.com/signup'
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        mt: 2,
                        color: 'main.white',
                        backgroundColor: 'main.green',
                        boxShadow: '0px 3px 3px #000',
                        '&:hover': {
                          backgroundColor: 'main.green',
                          boxShadow: '0 4px 10px #000'
                        }
                      }}
                    >
                      Try now
                    </Button>
                  </Box>
                </Grid>
                {!isXsScreen ? (
                  <Grid item container xs={6}>
                    <Grid item container xs={5} alignContent='flex-start' rowSpacing={2}>
                      <Grid item xs={12}>
                        <Typography variant='footerText'>
                          Quick Links
                        </Typography>
                      </Grid>
                      <Grid item container direction='column' xs={12} rowSpacing={1}>
                        <Grid item>
                          <Typography variant='footerLink'>
                            <Link href="#" sx={{ textDecoration: 'none', color: 'white' }}>
                              Home
                            </Link>
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant='footerLink'>
                            <Link href="#services" sx={{ textDecoration: 'none', color: 'white' }}>
                              Services
                            </Link>
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant='footerLink'>
                            <Link href="#features" sx={{ textDecoration: 'none', color: 'white' }}>
                              Features
                            </Link>
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant='footerLink'>
                            <Link href="#contact" sx={{ textDecoration: 'none', color: 'white' }}>
                              Contact
                            </Link>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item container xs={7} alignContent='flex-start' rowSpacing={2}>
                      <Grid item xs={12}>
                        <Typography variant='footerText'>Features</Typography>
                      </Grid>
                      <Grid item container direction='column' xs={12} rowSpacing={1}>
                        <Grid item>
                          <Typography variant='footerLink'>
                            <Link href="#features" sx={{ textDecoration: 'none', color: 'white' }}>
                              Apex
                            </Link>
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant='footerLink'>
                            <Link href="#features" sx={{ textDecoration: 'none', color: 'white' }}>
                              Pulse
                            </Link>
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant='footerLink'>
                            <Link href="#features" sx={{ textDecoration: 'none', color: 'white' }}>
                              Catena
                            </Link>
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant='footerLink'>
                            <Link href="#features" sx={{ textDecoration: 'none', color: 'white' }}>
                              Wave
                            </Link>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : ''}
                <Grid item container xs={6} sm={3} md={3} lg={3} alignContent='flex-start' rowSpacing={2}
                  id='contact'
                >
                  <Grid item xs={12}>
                    <Typography variant='footerText'>Contact</Typography>
                  </Grid>
                  <Grid item container xs={12} rowSpacing={1}>
                    <Grid item xs={12}>
                      <a href='tel:+916383376006'>
                        <Typography variant='footerLink'>
                          +91 63833 76006
                        </Typography>
                      </a>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant='footerLink'>Tamil Nadu, India.</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 4 }}>
                      <Typography variant='footerText'>Connect with us</Typography>
                    </Grid>
                    <Grid item container xs={12} rowSpacing={1} columnSpacing={1}>
                      <Grid item>
                        <a href='https://www.linkedin.com/products/dixsoft-business-solutions-dockit/' target="_blank" rel="noopener noreferrer">
                          <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="close"
                          >
                            <LinkedInIcon sx={{ color: 'main.white' }} />
                          </IconButton>
                        </a>
                      </Grid>
                      <Grid item>
                        <a href='https://www.instagram.com/dockit_live' target="_blank" rel="noopener noreferrer">
                          <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="close"
                          >
                            <InstagramIcon sx={{ color: 'main.white' }} />
                          </IconButton>
                        </a>
                      </Grid>
                      <Grid item>
                        <IconButton
                          edge="start"
                          color="inherit"
                          aria-label="close"
                        >
                          <FacebookIcon sx={{ color: 'main.white' }} />
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <a href='mailto:sales@teqshack.in' target="_blank" rel="noopener noreferrer">
                          <IconButton
                            edge="start"
                            aria-label="close"
                          >
                            <EmailIcon sx={{ color: 'main.white' }} />
                          </IconButton>
                        </a>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ mt: 3 }} >
                <Divider sx={{ color: 'main.white', borderColor: 'main.white', borderWidth: '0.25px' }}/>
              </Grid>
              <Grid item container xs={12} sx={{ px: 6, py: 2 }} rowSpacing={2}>
                <Grid item xs={12} sm={6} md={8} lg={8}>
                  <Typography sx={{ color: 'main.white' }}>©2024 Teqshack. All Rights Reserved</Typography>
                </Grid>
                {isXsScreen ? '' : (
                  <Grid item container xs={12} sm={6} md={4}>
                    <Grid item xs={6}>
                      <Typography sx={{ color: 'main.white', display: 'block', textAlign: 'end' }}> Terms & Conditions</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography sx={{ color: 'main.white', display: 'block', textAlign: 'end' }}> Privacy Policy</Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <FormDialog isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen} dialogKey='home' />
      </Grid>
    </React.Fragment>
  );
};

export default Home;
