import { createTheme } from '@mui/material/styles';

let theme = createTheme();
theme = createTheme(theme, {
  palette: {
    background: {
      default: '#f4f6f8',
      paper: '#ffffff'
    },
    main: {
      primary: '#205295',
      white: '#FFFFFF',
      green: '#84C602',
      lightGrey: '#E0E0E0',
      lightBlue: '#F1F7FF'
    }
  },
  typography: {
    h1: {
      fontWeight: 500,
      fontSize: 35,
      letterSpacing: '-0.24px'
    },
    h2: {
      fontWeight: 500,
      fontSize: 29,
      letterSpacing: '-0.24px'
    },
    h3: {
      fontWeight: 500,
      fontSize: 24,
      letterSpacing: '-0.06px'
    },
    h4: {
      fontWeight: 500,
      fontSize: 20,
      letterSpacing: '-0.06px'
    },
    h5: {
      fontWeight: 500,
      fontSize: 16,
      letterSpacing: '-0.05px'
    },
    h6: {
      fontWeight: 500,
      fontSize: 14,
      letterSpacing: '-0.05px'
    },
    overline: {
      fontWeight: 500
    },
    // New UI typography CSS
    content: {
      fontFamily: 'TSFont-Regular, sans-serif',
      fontSize: '15pt',
      [theme.breakpoints.down('sm')]: {
        fontSize: '14pt'
      },
      [theme.breakpoints.only('xl')]: {
        fontSize: '18pt'
      }
    },
    subContent: {
      fontFamily: 'TSFont-Bold, sans-serif',
      fontSize: '16pt'
    },
    subContentText: {
      fontFamily: 'TSFont-Regular, sans-serif',
      fontSize: '12pt'
    },
    contentHeading: {
      fontFamily: 'TSFont-ExtraBold, sans-serif',
      fontSize: '22pt'
    },
    contentSubHeading: {
      fontFamily: 'TSFont-Light, sans-serif',
      fontSize: '22pt'
    },
    cardText: {
      fontFamily: 'TSFont-Bold, sans-serif',
      fontSize: '16pt ',
      [theme.breakpoints.down('md')]: {
        fontSize: '14pt'
      },
      color: '#205295'
    },
    footerText: {
      fontFamily: 'TSFont-Bold, sans-serif',
      fontSize: '12pt',
      color: '#FFFFFF'
    },
    footerLink: {
      fontFamily: 'TSFont-Regular, sans-serif',
      fontSize: '12pt',
      color: '#FFFFFF'
    },
    labelText: {
      fontFamily: 'TSFont-Regular, sans-serif',
      fontSize: '11pt',
      color: '#A8A4A4'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'TSFont-Bold, sans-serif',
          fontSize: '1.3rem',
          borderRadius: '2rem',
          textTransform: 'none',
          paddingLeft: '24px',
          paddingRight: '24px'
        }
      }
    }
  }
});

export default theme;
