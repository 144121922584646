import MainLayout from './components/MainLayout';
import Home from './page/Home';

const Routes = [
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: '*', element: <Home /> },
      { path: '', element: <Home /> }
    ]
  }
];

export default Routes;
