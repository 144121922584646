import { Backdrop, Box, Button, CircularProgress, Dialog, DialogContent, FormControlLabel, Grid,
  IconButton, Link, Radio, RadioGroup, TextField, Typography,
  useMediaQuery } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PropTypes from 'prop-types';
import { validateEmailId, validateNumber, validateStringForNull } from '../utils/FieldValidataion';
import Services from '../utils/Services';
import APIData from '../utils/APIData';
import TSSnackbar from './tscomponents/TSSnackBar';
import { Utils } from '../utils/UtilsFunctions';
import ReCAPTCHA from 'react-google-recaptcha';

const getTextFieldStyle = () => {
  const style = {
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'main.white',
      borderRadius: '7px'
    },
    '& fieldset': {
      borderColor: 'main.primary'
    },
    '&:hover fieldset': {
      borderColor: 'main.primary !important'
    },
    '&.Mui-focused fieldset': {
      borderColor: 'main.primary'
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: '#A8A8A8',
      fontSize: '13pt',
      fontFamily: 'TSFont-Regular, sans-serif'
    },
    '& .MuiInputLabel-root': {
      color: '#A8A4A4',
      fontSize: '11pt',
      fontFamily: 'TSFont-Regular, sans-serif'
    }
  };
  return style;
};

const FormDialog = ({ isDialogOpen, setIsDialogOpen, dialogKey }) => {
  const [formKey, setFormKey] = useState('');
  const [userDetails, setUserDetails] = useState({
    name: '',
    phone: '',
    address: '',
    is_doctor: false,
    email: ''
  });
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isStartExperienceButtonDisabled, setIsStartExperienceButtonDisabled] = useState(true);

  const APIToken = {
    SEND_USER_DETAILS: 'FD01'
  };

  const isXsScreen = useMediaQuery('(max-width:600px)');
  const gridReference = useRef(null);
  const [isScrollable, setIsScrollable] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState);
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState);
  };

  const getSnackbar = (
    <TSSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  useEffect(() => {
    getIsScrollable();
  }, [isVisible]);

  useEffect(() => {
    setFormKey(dialogKey);
  }, [dialogKey]);

  const getIsScrollable = () => {
    const node = gridReference.current;
    if (node) {
      const scroll = node.scrollHeight > node.clientHeight;
      setIsScrollable(scroll);
    }
  };

  const getIsScrollableWithDelay = () => {
    setTimeout(() => {getIsScrollable();}, 500 );
  };

  useEffect(() => {
    setIsVisible(isDialogOpen);
  }, [isDialogOpen]);

  const useWindowSize = () => {
    const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerWidth, window.innerHeight]);
      };
      window.addEventListener('resize', handleResize);
      window.addEventListener('orientationchange', getIsScrollableWithDelay);
      return () => {
        window.removeEventListener('resize', handleResize);
        window.removeEventListener('orientationchange', getIsScrollableWithDelay);
      };
    }, []);
    return size;
  };

  const [screenWidth, screenHeight] = useWindowSize();

  const handleDoctorChange = (event) => {
    setUserDetails({
      ...userDetails,
      is_doctor: event.target.value === 'yes'
    });
  };

  const validateParams = (params, recaptchaTokenDetails) => {
    const finalParams = {};
    if (!validateStringForNull(recaptchaTokenDetails)) {
      throw new Error('Captcha verification required');
    }
    if (!validateStringForNull(params.name)) {
      throw new Error('Invalid Name');
    }
    if (!validateNumber(params.phone)) {
      throw new Error('Invalid Phone Number');
    }
    if (!validateStringForNull(params.address)) {
      throw new Error('Invalid Address');
    }
    if (!validateEmailId(params.email)) {
      throw new Error('Invalid Email Address');
    }
    finalParams.token = recaptchaTokenDetails;
    finalParams.username = params.name;
    finalParams.phone = params.phone;
    finalParams.address = params.address;
    finalParams.is_doctor = params.is_doctor;
    finalParams.email = params.email;

    return finalParams;
  };

  const handleStartExperienceClick = () => {
    setIsLoading(true);
    try {
      const params = validateParams(userDetails, recaptchaToken);
      raiseFreeDemoRequest(params);
    } catch (err) {
      setIsLoading(false);
      showSnackBar('error', err.message ?? 'Unable to proceed your request');
    }
  };

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.sendUserDetails && apiToken == APIToken.SEND_USER_DETAILS) {
      showSnackBar('success', response.message ?? 'Thank you for your interest! Our team will reach out to you soon');
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    if (apiData == APIData.sendUserDetails && apiToken == APIToken.SEND_USER_DETAILS) {
      showSnackBar('error', err.message ?? 'Unable to proceed your request');
    }
    setIsLoading(false);
  };

  const raiseFreeDemoRequest = (params) => {
    Services.sendBackendRequest({ apiData: APIData.sendUserDetails, params: params },
      APIToken.SEND_USER_DETAILS, processSuccess, processError);
  };

  const handleTextChange = (event) => {
    setUserDetails({ ...userDetails, [event.target.name]: event.target.value });
  };

  const getCloseButton = () => {
    return (
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end' }} >
        <IconButton
          color="inherit"
          onClick={() => setIsDialogOpen(false)}
          aria-label="close"
          sx={{ mr: { xs: 0, sm: 3, md: 5 } }}
        >
          <img src='/static/images/closeIcon.svg' alt="Remove" style={{ height: '1.6rem' }} />
        </IconButton>
      </Grid>
    );
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        open={isDialogOpen}
        onClose={() => {}}
        PaperProps={{
          sx: { borderRadius: '5px', p: 0, minWidth: { sm: '96%', md: '94%', lg: '86%' },
            height: { xs: '90vh', sm: screenHeight > 500 ? '52vh' : '80vh', md: '75vh', lg: '91vh' }, zIndex: 100 }
        }}
      >
        <DialogContent sx={{ p: 0, pt: { xs: 0, sm: 8 } }}>
          {isVisible && (
            <Grid container ref={gridReference}
              sx={{
                backgroundImage: 'url(/static/images/Formbackground.png)',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                height: !isScrollable ? '100%' : 'fit-content'
              }}
            >
              {isXsScreen ? (
                getCloseButton()
              ) : ''}
              <Grid item xs={12} sm={6} md={6} lg={6.5}
                sx={{ display: 'flex', justifyContent: 'center', mt: -7.5 }}
              >
                <Box
                  component='img'
                  sx={{
                    height: {
                      xs: '20rem',
                      sm: '30rem',
                      md: '35rem',
                      lg: '36rem'
                    }
                  }}
                  src='/static/images/Doctorformillustration.png'
                />
              </Grid>
              <Grid item container xs={12} sm={6} md={6} lg={5.5} sx={{ mt: { xs: -7, sm: -6, md: -6, lg: -5 },
                height: 'fit-content', px: { xs: 2, sm: 1 }, pt: '0px !important' }}>
                {!isXsScreen ? getCloseButton() : ''}
                <Grid item xs={12} sx={{ pr: 1 }}>
                  <Typography variant='subContentText'>
                    We need the details to personalize your DocKit experience
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', mb: userDetails.is_doctor ? 3.2 : 0 }}>
                  <Typography sx={{ mr: 2 }} variant="subContentText">
                    Are you a doctor?
                  </Typography>
                  <RadioGroup row
                    value={userDetails.is_doctor ? 'yes' : 'no'}
                    onChange={handleDoctorChange}
                  >
                    <FormControlLabel value="yes" control={<Radio sx={{
                      '&.Mui-checked': {
                        color: 'main.primary'
                      }
                    }} />} label={<Typography variant='subContentText'>Yes</Typography>} />
                    <FormControlLabel value="no" control={<Radio sx={{
                      '&.Mui-checked': {
                        color: 'main.primary'
                      }
                    }} />} label={<Typography variant='subContentText'>No</Typography>} />
                  </RadioGroup>
                </Grid>
                <Grid item container xs={12} sx={{ pr: { xs: 0, sm: 3, md: 5 }, pt: 0 }} >
                  {userDetails.is_doctor ? (
                    <Grid item container xs={12} sx={{ mt: { xs: '-9px', sm: '-9px', md: '-10px', lg: '-10px' }, mb: 1 }} >
                      <Grid item xs={screenWidth < 380 ? 2 : 1.8} sm={2} md={1.3} lg={1.1} >
                        <TextField
                          fullWidth
                          size='small'
                          margin="normal"
                          variant="outlined"
                          name="name"
                          autoComplete ='off'
                          disabled
                          label={<Typography variant='labelText' sx={{ color: 'black' }}>Dr.</Typography>}
                          sx={{ m: 0,
                            '& .MuiOutlinedInput-root': {
                              backgroundColor: '#DEE5EF',
                              borderRadius: '7px 0px 0px 7px'
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={screenWidth < 380 ? 10 : 10.2} sm={10} md={10.7} lg={10.9} >
                        <TextField
                          fullWidth
                          size='small'
                          margin="normal"
                          label='Full Name'
                          variant="outlined"
                          name="name"
                          autoComplete ='off'
                          sx={{ m: 0,
                            '& .MuiOutlinedInput-root': {
                              backgroundColor: 'main.white',
                              borderRadius: '0px 7px 7px 0px'
                            },
                            '& fieldset': {
                              borderColor: 'main.primary'
                            },
                            '& .MuiInputLabel-root.Mui-focused': {
                              color: '#A8A8A8',
                              fontSize: '13pt',
                              fontFamily: 'TSFont-Regular, sans-serif'
                            },
                            '& .MuiInputLabel-root': {
                              color: '#A8A4A4',
                              fontSize: '11pt',
                              fontFamily: 'TSFont-Regular, sans-serif'
                            }
                          }}
                          onChange={(event) => {handleTextChange(event);}}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        size='small'
                        margin="normal"
                        label='Full Name'
                        variant="outlined"
                        name="name"
                        autoComplete ='off'
                        sx={getTextFieldStyle()}
                        onChange={(event) => {handleTextChange(event);}}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} >
                    <TextField
                      fullWidth
                      size='small'
                      margin="normal"
                      variant="outlined"
                      name="phone"
                      autoComplete ='off'
                      sx={getTextFieldStyle()}
                      label='Phone Number'
                      onChange={(event) => {handleTextChange(event);}}
                    />
                  </Grid>
                  <Grid item xs={12} >
                    <TextField
                      fullWidth
                      size='small'
                      margin="normal"
                      variant="outlined"
                      name="email"
                      autoComplete ='off'
                      sx={getTextFieldStyle()}
                      label='Email'
                      onChange={(event) => {handleTextChange(event);}}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      size='small'
                      margin="normal"
                      variant="outlined"
                      name="address"
                      autoComplete ='off'
                      sx={getTextFieldStyle()}
                      label='Clinic/Hospital Pin Code'
                      onChange={(event) => {handleTextChange(event);}}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ReCAPTCHA
                      key={formKey}
                      sitekey='6Lc3-H4lAAAAAK1tql_SSIDWFQ7HOBPuSLFNnbC2'
                      data-type="image"
                      onChange={(token) => {
                        setRecaptchaToken(token);
                        setIsStartExperienceButtonDisabled(false);
                      }}
                      onExpired={() => {
                        setRecaptchaToken('');
                        setIsStartExperienceButtonDisabled(true);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ mt: 2 }}>
                    <Button variant="outlined"
                      endIcon={<ArrowForwardIcon sx={{ fontSize: '2rem !important', ml: -0.5 }}/>}
                      sx={{
                        backgroundColor: isStartExperienceButtonDisabled ? 'main.lightGrey' : 'main.primary',
                        color: isStartExperienceButtonDisabled ? '#205295 !important' : 'main.white',
                        boxShadow: '0 4px 10px #000',
                        '&:hover': {
                          borderColor: 'blue',
                          color: 'main.primary'
                        },
                        fontSize: '15pt',
                        height: '2.5rem', width: '100%', borderRadius: '2rem'
                      }}
                      disabled={isStartExperienceButtonDisabled}
                      onClick={() => handleStartExperienceClick()}
                    >
                        Start Experience
                    </Button>
                  </Grid>
                  <Grid item xs={12} sx={{ mt: { xs: 1, sm: 2, md: 2 }, pb: 7 }}>
                    <Typography variant='subContentText'>
                      Already got an account?{' '}
                      <Typography variant='subContentText'>
                        <Link href='https://dockit.teqshack.com' target="_blank" rel="noopener noreferrer"
                          sx={{ textDecoration: 'none', color: 'main.primary' }}>
                          Log In
                        </Link>
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </DialogContent>
      </Dialog>
      {getSnackbar}
      <Backdrop open={isLoading} sx={{ zIndex: 10000 }}>
        <CircularProgress style={{ color: 'main.primary' }} />
      </Backdrop>
    </React.Fragment>
  );
};

FormDialog.propTypes = {
  isDialogOpen: PropTypes.bool,
  setIsDialogOpen: PropTypes.func,
  dialogKey: PropTypes.string
};

export default FormDialog;

