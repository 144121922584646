import { Box, StyledEngineProvider } from '@mui/material';
import theme from './theme';
import './theme/App.css';
import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { useRoutes } from 'react-router-dom';
import Routes from './Routes';

const App = () => {
  const getContent = () => {
    const routes = useRoutes(Routes );
    return routes;
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: 'auto',
            minHeight: '100%',
            width: '100%',
            alignItems: 'flex-start'
          }}
        >
          {getContent()}
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;

