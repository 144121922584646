import { Box, Button, Drawer, Grid, IconButton, Link, List, ListItem, ListItemButton, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const NavBar = () => {
  const useWindowSize = () => {
    const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerWidth, window.innerHeight]);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    return size;
  };

  const [screenWidth] = useWindowSize();
  const [isNavBarOpen, setIsNavBarOpen] = useState(false);
  const isXsScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const smoothScroll = () => {
      document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
        anchor.addEventListener('click', function(e) {
          e.preventDefault();
          const targetId = e.target.getAttribute('href').substring(1);
          const targetSection = document.getElementById(targetId);
          if (targetSection == null) {
            window.scrollTo({ behavior: 'smooth', top: 0 });
          } else {
            targetSection.scrollIntoView({ behavior: 'smooth' });
          }
        });
      });
    };
    smoothScroll();
    return () => {
      document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
        anchor.removeEventListener('click', smoothScroll);
      });
    };
  }, []);

  const handleClick = (event) => {
    setIsNavBarOpen(true);
  };

  return (
    <Grid item container xs={12} sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'main.primary',
      borderRadius: '50px',
      pl: 3, pr: 1.75, py: 0.5,
      boxShadow: '0px 4px 2px #848181',
      position: 'sticky',
      top: 0,
      zIndex: 1000,
      mx: { xs: 2, md: 4, lg: 12 },
      mt: 2
    }} >
      <Grid item xs={2} sm={1.25} md={1} lg={1} sx={{ pl: { xs: 0, sm: 3 } }}>
        <a href=''>
          <Box
            component="img"
            sx={{
              mt: '4px',
              height: '54px'
            }}
            alt='DocKit'
            src='/static/images/Logo_Desktop.png'
          >
          </Box>
        </a>
      </Grid>
      {isXsScreen ? (
        <React.Fragment>
          <Grid item xs={9} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="submit"
              size="small"
              variant="outlined"
              href='https://dockit.teqshack.com/signup'
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: 'main.white',
                backgroundColor: 'main.green',
                boxShadow: '0px 3px 3px #000',
                '&:hover': {
                  backgroundColor: 'main.green',
                  boxShadow: '0 4px 10px #000'
                },
                whiteSpace: 'nowrap',
                height: '2.2rem',
                px: 1.6, fontSize: screenWidth < 380 ? '11.5pt' : '12.5pt',
                mr: 2
              }}
            >
             Try now
            </Button>
          </Grid>
          <Grid item xs={1} display='flex' justifyContent='flex-end' >
            <IconButton
              size="small"
              edge="end"
              aria-label="menu"
              sx={{ color: 'main.white' }}
              onClick={handleClick}
            >
              <MenuIcon sx={{ fontSize: '2rem' }} />
            </IconButton>
          </Grid>
        </React.Fragment>
      ) : (
        <Grid item container sm={10.75} md={11} lg={11} sx={{ pl: { lg: 3 } }} >
          <Grid item container xs={9} sm={7.5} md={8} lg={8}
            sx={{ pl: 0, display: 'flex', alignItems: 'center',
              justifyContent: 'flex-start' }}>
            <Grid item xs={3} >
              <Typography variant='cardText' sx={{ display: 'flex', justifyContent: 'center', fontSize: { sm: '13pt', md: '14pt' } }}>
                <Link href="/" sx={{ textDecoration: 'none', color: 'main.white' }}>
                  Home
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={3} >
              <Typography variant='cardText' sx={{ display: 'flex', justifyContent: 'center', fontSize: { sm: '13pt', md: '14pt' } }}>
                <Link href="#services" sx={{ textDecoration: 'none', color: 'main.white' }}>
                Services
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={3} >
              <Typography variant='cardText' sx={{ display: 'flex', justifyContent: 'center', fontSize: { sm: '13pt', md: '14pt' } }}>
                <Link href="#features" sx={{ textDecoration: 'none', color: 'main.white' }}>
                  Features
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={3} >
              <Typography variant='cardText' sx={{ display: 'flex', justifyContent: 'center', fontSize: { sm: '13pt', md: '14pt' } }}>
                <Link href="#contact" sx={{ textDecoration: 'none', color: 'main.white' }}>
                Contact
                </Link>
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={4.5} sm={4.5} md={4} lg={4} sx={{ display: 'flex', justifyContent: 'flex-end',
            pl: { xs: 0, sm: 1.5, md: 0 }
          }}>
            <Button
              type="submit"
              size="small"
              variant="outlined"
              href='https://dockit.teqshack.com/signup'
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: 'main.white',
                backgroundColor: 'main.green',
                boxShadow: '0px 3px 3px #000',
                '&:hover': {
                  backgroundColor: 'main.green',
                  boxShadow: '0 4px 10px #000'
                },
                whiteSpace: 'nowrap',
                height: '2.5rem',
                fontSize: { sm: '13pt', md: '15pt' }
              }}
            >
              Beta version - Try now
            </Button>
          </Grid>
        </Grid>
      )}
      <Drawer
        sx={{
          '& .MuiDrawer-paper': {
            width: '50%',
            zIndex: 10000
          }
        }}
        variant="persistent"
        anchor="right"
        open={isNavBarOpen}
      >
        <List >
          <ListItem sx={{ pl: 0, pt: 0, pb: 0 }}>
            <IconButton onClick={() => setIsNavBarOpen(false)}>
              <CloseIcon sx={{ fontSize: '1.8rem' }} />
            </IconButton>
          </ListItem>
          <ListItem disablePadding sx={{ borderBottom: '1px', borderLeft: '0px', borderTop: '0px',
            borderStyle: 'groove', borderBottomWidth: 'thin', borderColor: 'main.lightGrey' }}>
            <ListItemButton onClick={() => setIsNavBarOpen(false)}>
              <Typography variant='cardText' sx={{ color: 'main.primary' }}>
                <Link href="/" sx={{ textDecoration: 'none', color: 'main.primary' }}>Home</Link>
              </Typography>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ borderBottom: '1px', borderLeft: '0px', borderTop: '0px',
            borderStyle: 'groove', borderBottomWidth: 'thin', borderColor: 'main.lightGrey' }}>
            <ListItemButton onClick={() => setIsNavBarOpen(false)}>
              <Typography variant='cardText' sx={{ color: 'main.primary' }}>
                <Link href="#our_services" sx={{ textDecoration: 'none', color: 'main.primary' }}>Services</Link>
              </Typography>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ borderBottom: '1px', borderLeft: '0px', borderTop: '0px',
            borderStyle: 'groove', borderBottomWidth: 'thin', borderColor: 'main.lightGrey' }}>
            <ListItemButton onClick={() => setIsNavBarOpen(false)}>
              <Typography variant='cardText' sx={{ color: 'main.primary' }}>
                <Link href="#features" sx={{ textDecoration: 'none', color: 'main.primary' }}>Features</Link>
              </Typography>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ borderBottom: '1px', borderLeft: '0px', borderTop: '0px',
            borderStyle: 'groove', borderBottomWidth: 'thin', borderColor: 'main.lightGrey' }}>
            <ListItemButton onClick={() => setIsNavBarOpen(false)}>
              <Typography variant='cardText' sx={{ color: 'main.primary' }}>
                <Link href="#contact" sx={{ textDecoration: 'none', color: 'main.primary' }}>Contact</Link>
              </Typography>
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </Grid>
  );
};

export default NavBar;
